import moment from "moment";
import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm, useWatch } from "react-hook-form";
import { MdOutlineAddAPhoto, MdDeleteForever } from "react-icons/md";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { redirect, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Input from "../../components/utils/Input";
import ReturnTitle from "../../components/utils/ReturnTitle";
import StarRating from "../../components/utils/StarRating";
import { getImageURL } from "../../helpers/all";
import { editAccount, editAvatar } from "../../services/account";
import { authEditPassword, authEditPhone } from "../../services/auth";
import { setUser } from "../../store/reducers/authSlice";
import Photo from "../../assets/imgs/photo.jpg";

const Profile = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [showShare, setShowShare] = useState(false);
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      options: {
        telegramNotificationStatus: user?.options?.telegramNotificationStatus ? false : true,
        telegramNickname: "",
      },
    },
  });

  const data = useWatch({ control });
  const {
    register: registerPassword,
    formState: { errors: errorsPassword, isValid: isValidPassword },
    handleSubmit: handleSubmitPassword,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const onSubmitPassword = useCallback((data) => {
    if (data.passwordNew != data.passwordConfirm) {
      return NotificationManager.error("Пароли не совпадают");
    }
    authEditPassword(data)
      .then(() => {
        NotificationManager.success("Пароль успешно обновлен");
      })
      .catch(
        (err) =>
          err &&
          NotificationManager.error(
            err?.response?.data?.error ?? "Ошибка при сохранении"
          )
      );
  }, []);

  const onTele = useCallback(() => {
    if (!data?.options?.telegramNickname && !user?.options?.telegramNickname) {
      return NotificationManager.error("Введите никнейм");
    }
    editAccount(data)
      .then((res) => {
        NotificationManager.success("Успешно подключено");
        dispatch(setUser(res))
        if (!res?.options?.telegramStatus) {
          return window.location.href = "https://t.me/speedytrade_bot"
        }
      })
      .catch(
        (err) =>
          err &&
          NotificationManager.error(
            err?.response?.data?.error ?? "Ошибка при сохранении"
          )
      );
  }, [data]);

  const onUploadAvatar = useCallback(
    (e) => {
      e.preventDefault();
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        editAvatar(formData)
          .then((res) => {
            NotificationManager.success("Аватар успешно изменен");
            res && dispatch(setUser({ ...user, media: res.media }));
          })
          .catch(
            (err) =>
              err &&
              NotificationManager.error(
                err?.response?.data?.error ?? "Ошибка при сохранении"
              )
          );
      }
    },
    [user]
  );


  return (
    <div className="main">
      <Meta title="Профиль" />
      <ReturnTitle link="/account" title="Профиль" />

      <div className="profile-form">
        <Row className="flex-lg-row-reverse">
          <Col xs={12} xl={4}>
            <div className="d-flex flex-column flex-sm-row flex-xl-column align-items-center mb-4 mb-xl-0">
              <div className="user-photo">
                <img
                  src={getImageURL({
                    path: user?.media,
                    size: "mini",
                    type: "user",
                  })
                  }
                  alt="userphoto"
                />
                <div className="btns">
                  {/* <button type="button" onClick={onDelAvatar} className="mb-3">
                    <MdDeleteForever />
                    <span className="ms-1">Удалить фото</span>
                  </button> */}
                  <div className="upload">
                    <div className="upload-text">
                      <MdOutlineAddAPhoto />
                      <span className="ms-1">Загрузить фото</span>
                    </div>
                    <input
                      type="file"
                      id="input-file-upload"
                      name="file"
                      onChange={(e) => onUploadAvatar(e)}
                    />
                  </div>
                </div>
                {/* <label htmlFor="user-photo_upload">
                  <input
                    type="file"
                    id="input-file-upload"
                    className="d-none"
                    name="file"
                    onChange={onUploadAvatar}
                  />
                  <FiEdit />
                </label> */}
              </div>

              <div className="d-flex flex-column align-items-center align-items-sm-start align-items-xl-center justify-content-center">
                <StarRating value={user?.rating ?? 0} />
                <div className="mt-2 mt-sm-4">
                  {user?.createdAt ? moment(user.createdAt).fromNow(1) : ""} на
                  платформе
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            {/* <div className="mb-5">
              <Row className="g-3 g-xl-4">
                <Col md={3}>
                  О себе
                </Col>
                <Col md={9}>
                  <Textarea
                    name="about"
                    errors={errors}
                    defaultValue={form?.about}
                    register={register}
                  />
                </Col>
              </Row>
              <div className="d-flex mt-4">
                <button
                  type="submit"
                  className="btn-5"
                  disabled={!isValid}
                  onClick={handleSubmit(onSubmit)}
                >
                  Сохранить изменения
                </button>
                <button
                  type="button"
                  className="btn-1 ms-2 ms-sm-4"
                  onClick={() => reset()}
                >
                  Отмена
                </button>
              </div>
            </div> */}

            {/* <div action="" className="mb-5">
              <Row className="g-3 g-xl-4">
                <Col md={3}>Номер телефона:</Col>
                <Col md={9}>
                  <Input
                    mask="7(999)999-99-99"
                    name="phone"
                    errors={errorsPhone}
                    defaultValue={form?.phone}
                    register={registerPhone}
                    validation={{ required: "Обязательное поле" }}
                  />
                </Col>
                {!user?.phone ? (
                  <>
                    <Col md={12}>
                      <p className="rose fs-09 mt-1">
                        Только верифицированные пользовтаели могут публиковать
                        объявления на бирже Game.
                      </p>
                    </Col>
                    <Col md={12}>
                      <Button
                        variant="danger"
                        disabled={!isValidPhone}
                        onClick={handleSubmitPhone(onSubmitPhone)}
                      >
                        Пройти верификацию
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col md={12}>
                    <Button
                      variant="primary"
                      disabled={!isValidPhone}
                      onClick={handleSubmitPhone(onSubmitPhone)}
                    >
                      Изменить номер телефона
                    </Button>
                  </Col>
                )}
              </Row>
            </div> */}

            <div>
              <h6>Изменить пароль</h6>
              <Row className="g-3 g-xl-4 align-items-center">
                <Col md={3}>
                  <div>Старый пароль:</div>
                </Col>
                <Col md={9}>
                  <Input
                    autoComplete="new-password"
                    type="password"
                    name="passwordOld"
                    errors={errorsPassword}
                    register={registerPassword}
                    validation={{ required: "Обязательное поле" }}
                  />
                </Col>
                <Col md={3}>
                  <div>Новый пароль:</div>
                </Col>
                <Col md={9}>
                  <Input
                    autoComplete="new-password"
                    type="password"
                    name="passwordNew"
                    errors={errorsPassword}
                    register={registerPassword}
                    validation={{
                      required: "Обязательное поле",
                      minLength: {
                        value: 6,
                        message: "Минимальное кол-во символов 6",
                      },
                      maxLength: {
                        value: 30,
                        message: "Максимальное кол-во символов 30",
                      },
                      pattern: {
                        value: /(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z]{6,}/g,
                        message:
                          "Пароль должен содержать не менее 6 символов латинского алфавита или цифр",
                      },
                    }}
                  />
                </Col>
                <Col md={3}>
                  <div>Повторить пароль:</div>
                </Col>
                <Col md={9}>
                  <Input
                    autoComplete="new-password"
                    type="password"
                    name="passwordConfirm"
                    errors={errorsPassword}
                    register={registerPassword}
                    validation={{
                      required: "Обязательное поле",
                      minLength: {
                        value: 6,
                        message: "Минимальное кол-во символов 6",
                      },
                      maxLength: {
                        value: 30,
                        message: "Максимальное кол-во символов 30",
                      },
                      pattern: {
                        value: /(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z]{6,}/g,
                        message:
                          "Пароль должен содержать не менее 6 символов латинского алфавита или цифр",
                      },
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <button
                    type="button"
                    className="btn-5 w-xs-100"
                    disabled={!isValidPassword}
                    onClick={handleSubmitPassword(onSubmitPassword)}
                  >
                    Изменить пароль
                  </button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <div className="telegram">
          <div className="telegram-header ">
            <span>Мы будем оповещать вас о новых сообщениях.</span>
          </div>
          <div className="telegram-preview">
            <div className="img">
              <img src="/imgs/telegram.webp" alt="user" />
            </div>
            <div className="content">
              <div className="header">
                <h4 className="black mb-0 mb-md-2">Telegram</h4>
              </div>
              <p className="mb-3">Telegram-бот для оповещения о новых сообщениях.</p>
              <p>
                <span className="blue me-1">Необходимо</span>
                <span className="color-2">привязать Telegram.</span>
              </p>
              <Col className="mt-3" xs={12}>
                <label>
                  <input
                    onChange={(e) => setValue("options.telegramNotificationStatus", e.target.checked ? true : false)}
                    type="checkbox"
                    defaultChecked={user?.options?.telegramNotificationStatus}
                  />
                  <span>Получать уведомления</span>
                </label>
              </Col>
              <Row className="mt-3 align-items-center">
                <Col md={3}>
                  {user?.options?.telegramNickname ?
                    <div>Никнейм:</div>
                    :
                    <div>Введите никнейм:</div>
                  }
                </Col>
                <Col md={9}>
                  {user?.options?.telegramNickname ?
                    <div>{user?.options?.telegramNickname}</div>
                    :
                    <Input
                      type="text"
                      defaultValue={data?.options?.telegramNickname}
                      name="options.telegramNickname"
                      register={register}
                    />
                  }
                </Col>
              </Row>
              {user?.options?.telegramNotificationStatus ? <button type="submit" class="btn-7 mt-4" onClick={handleSubmit(onTele)}>Отключить</button> :
                <button type="submit" class="btn-5 mt-4" onClick={handleSubmit(onTele)}>Подключить</button>}
            </div>
          </div>
        </div>

      </div>

      {/* <Row className="g-3 gy-xl-4 mb-md-4">
        <Col xs={6} xxl={6}>
          <Row className="g-3 gy-xl-4">
            <Col md={12} xl={12}>
              <Textarea
                label="О себе"
                name="about"
                errors={errors}
                defaultValue={form?.about}
                register={register}
              />
            </Col>

            <Col md={12} xl={12}>
              <Button
                variant="primary"
                disabled={!isValid}
                onClick={handleSubmit(onSubmit)}
                className="mb-4"
              >
                Сохранить изменения
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={6} xxl={6}>
          <Row className="g-3 gy-xl-4">
            <Col md={12}>
              <Input
                mask="7(999)999-99-99"
                label="Номер телефона"
                name="phone"
                errors={errorsPhone}
                defaultValue={form?.phone}
                register={registerPhone}
                validation={{ required: "Обязательное поле" }}
              />
            </Col>
            {!user?.phone ? (
              <>
                <Col md={12}>
                  <p className="rose fs-09 mt-1">
                    Только верифицированные пользовтаели могут публиковать
                    объявления на бирже Game.
                  </p>
                </Col>
                <Col md={12}>
                  <Button
                    variant="danger"
                    disabled={!isValidPhone}
                    onClick={handleSubmitPhone(onSubmitPhone)}
                  >
                    Пройти верификацию
                  </Button>
                </Col>
              </>
            ) : (
              <Col md={12}>
                <Button
                  variant="primary"
                  disabled={!isValidPhone}
                  onClick={handleSubmitPhone(onSubmitPhone)}
                >
                  Изменить номер телефона
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row> */}

      <Modal show={showShare} onHide={setShowShare} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 className="mb-3">Поделитесь профилем</h4>
          <Input
            onClick={(e) => e.target.select()}
            readOnly
            defaultValue={`${process.env.REACT_APP_SITE_URL}/profile/${user.id}`}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Profile;
