import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReturnTitle from "../../components/utils/ReturnTitle";
import Textarea from "../../components/utils/Textarea";
import { getGamesList, getUserGame } from "../../services/game";
import Select from "../../components/utils/Select";
import { useForm, useWatch } from "react-hook-form";
import { useCallback } from "react";
import { NotificationManager } from "react-notifications";
import { createUserProduct, editUserProduct, getUserProduct } from "../../services/product";
import Loader from "../../components/utils/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import { removeDescendants } from "../../helpers/all";
import Input from "../../components/utils/Input";

const AddOffer = () => {
  const { id } = useParams();
  const [games, setGames] = useState({ items: [], loading: true });
  const { state } = useLocation();
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      ...state ? state : {},
      count: 1,
      status: 1,
    },
  });

  const data = useWatch({ control });
  const [sum, setSum] = useState(0);
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState({}); // Хранение выбранных значений


  const onSubmit = useCallback((data) => {
    if (!data.categoryId) {
      return NotificationManager.error(
        "Выберите игру"
      )
    }
    if (!data.region && data?.game?.regions?.length > 0) {
      return NotificationManager.error(
        "Выберите регион"
      )
    }
    if (!data.server && data?.servers?.length > 0) {
      return NotificationManager.error(
        "Выберите сервер"
      )
    }
    if (!data.param && data?.game?.params?.length > 0) {
      return NotificationManager.error(
        "Выберите объект продажи"
      )
    }
    if (data?.options && data?.options.length > 0 && (!data?.option || data?.options?.filter((option) => option.parent === null).length > data?.option?.length)) {
      return NotificationManager.error(
        "Выберите характеристики продукта"
      )
    }
    if (!data.price || data.price == 0) {
      return NotificationManager.error(
        "Введите цену объявления"
      )
    }
    if (!data.count || data.count < 1) {
      return NotificationManager.error(
        "Введите количество товара"
      )
    }
    if (!data.notDesc && data.price < 1) {
      return NotificationManager.error(
        "Стоимость не ниже 1 рубля"
      )
    }
    if (data.notDesc && (!data?.price || !data?.minCount || data?.price * data?.minCount < 1)) {
      return NotificationManager.error(
        "Продаваемый обьем валюты должен стоит не менее 1 рубля"
      )
    }
    if (!id) {
      createUserProduct({
        id: data.id,
        categoryId: data.categoryId,
        region: data.region,
        server: data.server,
        param: data.param,
        option: data.option,
        desc: data.desc,
        count: data.count,
        price: data.price,
        status: data.status,
        data: {
          minCount: data.minCount,
          typeCount: data.typeCount
        },
        protectedData: data.protectedData,

      })
        .then(() => {
          NotificationManager.success("Лот создан");
          reset();
          navigate(-1);
        })
        .catch((error) =>
          NotificationManager.error(
            typeof error?.response?.data?.error == "string"
              ? error.response.data.error
              : "Неизвестная ошибка при отправке"
          )
        );
    }
    else {
      editUserProduct({
        id: data.id,
        categoryId: data.categoryId,
        region: data.region,
        server: data.server,
        param: data.param,
        option: data.option,
        desc: data.desc,
        count: data.count,
        price: data.price,
        status: data.status,
        data: {
          minCount: data.minCount,
          typeCount: data.typeCount
        },
        protectedData: data.protectedData,
      })
        .then(() => {
          NotificationManager.success("Лот обновлён");
          reset();
          navigate(-1);
        })
        .catch(
          (err) =>
            err &&
            NotificationManager.error(
              err?.response?.data?.error ?? "Неизвестная ошибка при отправке"
            )
        );
    }
  }, []);

  useEffect(() => {
    if (!id) {
      getGamesList()
        .then((res) => {
          setGames((prev) => ({
            ...prev,
            items: res,
            loading: false
          }));
        })
        .catch(() => setGames((prev) => ({ ...prev, loading: false })));
    }
    if (!id && !state) {
      setSum(2)
    }
  }, []);
  useEffect(() => {
    if (data.categoryId && sum > 1) {
      getUserGame({ id: data.categoryId })
        .then((res) => {
          reset({
            text: data.text,
            count: data.count,
            price: data.price,
            categoryId: data.categoryId,
            game: res,
            region: res?.regions && res?.regions[0]?.status == 0 ? res?.regions[0]?.id : null,
          })
        })
        .catch(() => setGames((prev) => ({ ...prev, loading: false })));
    }
  }, [data.categoryId]);
  useEffect(() => {
    if (id) {
      getUserProduct({ id: id })
        .then((res) => {
          setGames((prev) => ({
            ...prev,
            items: res.categories,
            loading: false
          }));
          let id = res.product.id;
          let categoryId = res.product.categoryId;
          let game = res.category;
          let desc = res.product.desc;
          let count = res.product.count;
          let price = res.product.price;
          let status = res.product.status;
          let region;
          let servers;
          let server;
          let param = res.product.paramId;
          let minCount = res.product.data?.minCount;
          let typeCount = res.product.data?.typeCount;
          let one = res.product.param.data?.one;
          let auto = res.product.param.data?.auto;
          let protectedData;
          if (auto) {
            protectedData = res.product.protectedData
          }
          let currency = res.product.param.data?.currency;
          let serverView = res.product.param.data?.serverView;
          let options;
          let option = [];
          if (res?.product?.regionId) {
            region = res.product.regionId
            if (!serverView) {
              let regionIndex = res?.category?.regions.findIndex((e) => e.id === region);
              if (res?.category?.regions[regionIndex]?.servers) {
                servers = res.category.regions[regionIndex].servers
                server = res.product.serverId
              }
            }
          }
          let paramIndex = res?.category?.params.findIndex((e) => e.id === param);
          if (res?.category?.params[paramIndex]?.options?.length > 0) {

            options = createTree(res.category.params[paramIndex].options, 'id', 'parent', null).sort((a, b) => a.id - b.id);

            option = res.product?.options.map(opt => ({

              ...opt.option,
              children: createTree(res.category.params[paramIndex].options, 'id', 'parent', opt.option.id).sort((a, b) => a.id - b.id),
              value: opt?.value,
            }));


          }


          reset({
            id: id,
            categoryId: categoryId,
            game: game,
            region: region ? region : null,
            servers: servers ? servers : null,
            server: server ? server : null,
            param: param,
            notDesc: currency ? currency : null,
            one: one ? one : null,
            auto: auto ? auto : null,
            serverView: serverView ? serverView : null,
            minCount: minCount ? minCount : null,
            typeCount: typeCount ? typeCount : null,
            options: options ? options : null,
            option: option ? option : null,
            protectedData: protectedData ? protectedData : null,
            desc: desc,
            count: count ? count : 1,
            price: price,
            status: status,
          });
          setSum(1)
        })
        .catch((error) => {
          NotificationManager.error(
            typeof error?.response?.data?.error == "string"
              ? error.response.data.error
              : "Неизвестная ошибка при загрузки страницы"
          )
          setSum(1)
        }
        );

    }
    if (state) {
      setSelectedValues({ ...state.selectedValues })
    }
  }, []);


  const createTree = (data, idProp, parentProp, parentId) =>
    data.filter((n) => parentId === (n[parentProp] ?? null))
      .map((n) => ({
        ...n,
        children: createTree(data, idProp, parentProp, n[idProp]),
      }));


  const onSaveOption = (option) => {
    setValue('option', removeDescendants(data, option));
  };



  const maxOption = (tree) => {
    return (
      <>
        <Col xs={12} sm={3} md={2} className="d-flex align-items-center">
          <span>{tree.title}</span>
        </Col>
        <Col xs={12} sm={9} md={10} className="d-flex align-items-center">
          <Input
            type="number"
            placeholder={'0'}
            max={tree?.data?.max}
            min={tree?.data?.min}
            value={parseInt(data?.option?.find(e => e.id == tree.id)?.value)}
            onChange={(g) => {
              if (parseInt(g) >= tree?.data?.min && parseInt(g) <= tree?.data?.max || g == "") onSaveOption({ ...tree, value: parseInt(g) })
            }}
          />
        </Col>
      </>
    );
  };
  const renderSelects = (tree) => {
    if (tree?.children && tree?.children.length > 0) {
      if (tree?.children[0].data?.max) {
        return tree.children.map(child =>
          maxOption(child)
        );
      }
      else {
        return (
          <>
            {tree.children.length > 1 &&
              <>
                <Col xs={12} sm={3} md={2}>
                  {tree.title}
                </Col>
                <Col xs={12} sm={9} md={10}>
                  <Select
                    value={data.option && data.option.find(e => e.parent == tree.id) && data.option.find(e => e.parent == tree.id).id}
                    title={tree?.title}
                    onClick={(e) => onSaveOption(e.data)}
                    data={tree?.children?.sort((a, b) => a.priority - b.priority).map((item) => ({ value: item.id, data: item, title: item.title }))}
                  />
                </Col>
              </>
            }
            {data.option && data.option.find(e => e.parent == tree.id) &&
              renderSelects(data.option.find(e => e.parent == tree.id))
            }
          </>
        );
      }
    }
    return null;
  };

  useEffect(() => {
    if (data?.serverView) {


      reset({
        ...data,
        server: null,
        servers: null,
      });

    }
  }, [data?.serverView]);

  useEffect(() => {
    if (data.region && sum > 1) {
      let servers = null;
      if (!data?.serverView) {
        let serverIndex = data.game.regions.findIndex(
          (e) => e.id === data.region
        );
        servers = data.game.regions[serverIndex].servers;
      }
      reset({
        ...data,
        server: null,
        servers: servers?.length > 0 ? servers : null,
      });
    }
  }, [data.region]);
  useEffect(() => {
    if (data.param && sum > 1) {
      let optionsIndex = data.game.params.findIndex((e) => e.id === data.param);
      let one = data.game?.params[optionsIndex]?.data?.one;
      let auto = data.game?.params[optionsIndex]?.data?.auto;
      let currency = data.game?.params[optionsIndex]?.data?.currency;
      let serverView = data.game?.params[optionsIndex]?.data?.serverView;
      let options = data?.game?.params[optionsIndex]?.options ? createTree(data?.game?.params[optionsIndex]?.options, 'id', 'parent', null).sort((a, b) => a.id - b.id) : null;
      reset({
        ...data,
        notDesc: currency ? currency : null,
        one: one ? one : null,
        auto: auto ? auto : null,
        serverView: serverView ? serverView : null,
        option: null,
        options: options?.length > 0 ? options : null,
      });
      setSelectedValues({});
    }
  }, [data.param]);
  if (games.loading || id && sum == 0) {
    return <Loader full />;
  }
  return (
    <div className="main">
      <Meta title="Мои объявления" />
      <ReturnTitle link={"/account/offers"} title={"Мои объявления"} />
      <p className="mb-4">Добавление нового объявления</p>

      <div action="" className="add-offer">
        <Row className="g-3 g-lg-4 align-items-center">
          <Col xs={12} sm={3} md={2}>
            Игра:
          </Col>
          <Col xs={12} sm={9} md={10}>
            {games?.items?.length > 0 && (
              <Select
                value={data.categoryId}
                title="Выбрать"
                onClick={(e) => { setValue("categoryId", e.value), setSum(2) }}
                data={games.items.map((item) => ({
                  value: item.id,
                  title: item.title,
                }))}
              />
            )}
          </Col>

          {data?.game?.regions?.length > 0 && data?.game?.regions[0].status != 0 && (
            <>
              <Col xs={12} sm={3} md={2}>
                Регион:
              </Col>
              <Col xs={12} sm={9} md={10}>
                <Select
                  value={data.region}
                  title="Выбрать"
                  onClick={(e) => {
                    setSum(2)
                    setValue("region", e.value)

                  }}
                  data={data.game.regions.sort((a, b) => a.priority - b.priority).map((item) => ({
                    value: item.id,
                    title: item.title,
                  }))}
                // validation={{ required: "Обязательное поле" }}
                />
              </Col>
            </>
          )}

          {data?.servers && data?.servers?.length > 0 && (
            <>
              <Col xs={12} sm={3} md={2}>
                Сервер:
              </Col>
              <Col xs={12} sm={9} md={10}>
                <Select
                  value={data.server}
                  title="Выбрать"
                  onClick={(e) => {
                    setValue("server", e.value)
                  }}
                  data={data.servers.sort((a, b) => a.priority - b.priority).map((item) => ({
                    value: item.id,
                    title: item.title,
                  }))}
                // validation={{ required: "Обязательное поле" }}
                />
              </Col>
            </>
          )}

          {data?.game?.params?.length > 0 && (
            <>
              <Col xs={12} sm={3} md={2}>
                Категория лота:
              </Col>
              <Col xs={12} sm={9} md={10}>
                <Select
                  value={data.param}
                  title="Что вы продаете?"
                  onClick={(e) => {
                    setValue("param", e.value)
                    setSum(2)
                  }}
                  data={data.game.params.sort((a, b) => a.priority - b.priority).map((item) => ({
                    value: item.id,
                    title: item.title,
                  }))}
                // validation={{ required: "Обязательное поле" }}
                />
              </Col>
            </>
          )}

          {data?.options && data?.options?.length > 0 &&
            data.options.map((e) => {
              return (
                <>
                  {e.data?.max ?
                    maxOption(e)
                    :
                    renderSelects(e)
                  }
                </>
              )
            })
          }

          <Col xs={12}>
            <hr className="horizontal" />
          </Col>
          {!data?.notDesc &&
            <>
              <Col xs={12} sm={3} md={2}>
                Описание:
              </Col>
              <Col xs={12} sm={9} md={10}>
                <Textarea
                  type="text"
                  defaultValue={data.desc}
                  onChange={(e) => setValue("desc", e)}
                // validation={{ required: "Обязательное поле" }}
                />
              </Col>


              <Col xs={12}>
                <hr className="horizontal" />
              </Col>
            </>
          }

          {!data?.one && data?.notDesc &&
            <>
              <Col xs={12} sm={3} md={2}>
                Количество:
              </Col>
              <Col xs={12} sm={9} md={3} className="d-flex align-items-center">
                <Input
                  type={"number"}
                  value={data.count}
                  onChange={(e) => {
                    if (/^\d*$/.test(e)) {
                      setValue("count", e)
                    }
                  }}
                />
              </Col>
            </>
          }
          {data?.notDesc &&
            <>
              <Col xs={12} sm={3} md={4}>
                Единица измерения:
              </Col>
              <Col xs={12} sm={9} md={3} className="align-items-center ">
                <Select
                  className="width-100px"
                  value={data.typeCount}
                  title={"шт"}
                  onClick={(e) => setValue("typeCount", e.value)}
                  data={[
                    { value: "шт", title: "шт" },
                    { value: "кк", title: "кк" },
                    { value: "к", title: "к" }
                  ]}
                />
              </Col>
            </>
          }
          <Col xs={12} sm={3} md={2}>
            Цена:
          </Col>
          <Col xs={12} sm={9} md={3} className="d-flex align-items-center">
            <Input
              type="number"
              value={data.price}
              onChange={(e) => {
                const value = e;
                let isValid;
                if (value >= 1) { isValid = /^\d+(\.\d{0,2})?$/.test(value); }
                else { isValid = /^\d+(\.\d{0,4})?$/.test(value); }
                if (isValid || !value) {
                  setValue("price", value);
                }
              }}
            />
            <span className="ms-3">руб.</span>
          </Col>
          {data?.notDesc &&
            <>
              <Col xs={12} sm={3} md={4}>
                Минимальный обьем покупки:
              </Col>
              <Col xs={12} sm={9} md={3} className="align-items-center">
                <Input
                  type={"number"}
                  value={data.minCount}
                  onChange={(e) => {
                    if (/^\d*$/.test(e)) {
                      setValue("minCount", e)
                    }

                  }}
                />
              </Col>
            </>
          }
          {!data?.one && !data?.notDesc &&
            <>
              <Col xs={12} sm={3} md={2}>
                Количество:
              </Col>
              <Col xs={12} sm={9} md={4} className="d-flex align-items-center">
                <Input
                  type={"number"}
                  value={data.count}
                  onChange={(e) => {
                    if (/^\d*$/.test(e)) {
                      setValue("count", e)
                    }

                  }}
                />
              </Col>
            </>
          }
          <Col xs={12}>
            <p>Цена для покупателей: {data?.game?.commission ? (data?.price && Math.round(((data?.price) * 1 + (data?.price / 100 * data?.game?.commission)) * 1000000) / 1000000) : data.price}</p>
          </Col>
          {data.status != -1 ?
            <Col xs={12}>
              <label>
                <input
                  onChange={(e) => setValue("status", e.target.checked ? 1 : 0)}
                  type="checkbox"
                  checked={data?.status && data.status === 1}
                />
                <span>Активное</span>
              </label>
            </Col>
            :
            <Col xs={12}>
              <label className="color-1 justify-content-center">
                Обьявление заблокировано! Обратитесь к администратору.
              </label>
            </Col>
          }
          <Col xs={12}>
            <label>
              <input
                onChange={(e) => setValue("accept", e.target.checked ? 1 : 0)}
                type="checkbox"
                checked={data?.accept && data.accept === 1}
              />
              <span>Потвердите что вы не нарушаете права правообладателя</span>
            </label>
          </Col>
          <Col xs={12}>
            <hr className="horizontal" />
          </Col>
          {data?.auto &&
            <>
              <Col xs={12} sm={12} md={5}>
                Сообщение покупателю после оплаты:
              </Col>
              <Col xs={12} sm={12} md={7}>
                <Textarea
                  type="text"
                  defaultValue={data?.protectedData?.auto}
                  onChange={(e) => setValue("protectedData.auto", e)}
                // validation={{ required: "Обязательное поле" }}
                />
              </Col>


              <Col xs={12}>
                <hr className="horizontal" />
              </Col>
            </>
          }
        </Row>

        <div className="d-sm-flex mt-3 mt-sm-4">
          {!id ?
            <button
              type="button"
              className="btn-5 w-xs-100"
              onClick={handleSubmit(onSubmit)}
              disabled={data?.status == -1 || data?.accept != 1}
            >
              Опубликовать объявление
            </button>
            :
            <button
              type="button"
              className="btn-5 w-xs-100"
              onClick={handleSubmit(onSubmit)}
              disabled={data?.status == -1 || data?.accept != 1}
            >

              Сохранить изменения
            </button>
          }
          <button
            onClick={() => navigate(-1)}
            type="reset"
            className="btn-1 w-xs-100 ms-sm-3 mt-2 mt-sm-0"
          >
            Отмена
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddOffer;
