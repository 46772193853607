import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import bgImg from '../assets/imgs/bg/gradient.jpg';
import { Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getCert } from '../services/cert';
import Loader from '../components/utils/Loader';

const Document = () => {
  const { documentId } = useParams();
  const [certs, setCerts] = useState({
    loading: true,
    items: [],
  });
  useEffect(() => {
    getCert({ id: documentId })
      .then((res) => {
        setCerts((prev) => ({
          prev,
          loading: false,
          ...res,
        }))
      })
      .catch(() => setCerts((prev) => ({ ...prev, loading: false })));
  }, []);


  if (certs.loading) {
    return <Loader full />;
  }
  return (
    <main>
      <Container>
        <div className='rules'>
          <div className='top'>
            <h1>{certs.title}</h1>
          </div>
          <div className='content' dangerouslySetInnerHTML={{ __html: certs?.content }} />
          {certs?.data &&
            <Table responsive striped borderless>
              <thead>
                <tr>
                  <th>Нарушение</th>
                  <th>Санкции</th>
                </tr>
              </thead>
              <tbody>
                {certs.data.map(row => (
                  < tr >
                    <td dangerouslySetInnerHTML={{ __html: row?.desc1 }} />
                    <td dangerouslySetInnerHTML={{ __html: row?.desc2 }} />
                  </tr>
                ))}
              </tbody>
            </Table>
          }
          <div className='content' dangerouslySetInnerHTML={{ __html: certs?.desc }} />
        </div>
      </Container>
    </main >
  );
};

export default Document;